import { Buffer } from "buffer";
import PSPDFKit from "pspdfkit";
import React, { useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import arrayBufferToBuffer from "../../utils/arrayBufferToBuffer";
import getLicenseKey from "../../utils/getLicenseKey";
import "./annexUpload.css";
import { WatermarkButton } from "./components/WatermarkButton";

const BLANK_PDF = "https://asklesoft-data.s3.eu-west-3.amazonaws.com/blank.pdf";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const annex_IdInput = urlParams.get("annex_IdInput") || undefined;
const annex_TitleInput = urlParams.get("annex_TitleInput") || undefined;
const annex_DescriptionInput =
  urlParams.get("annex_DescriptionInput") || undefined;
const projUuid = urlParams.get("projUuid") || undefined;
const devUuid = urlParams.get("devUuid") || undefined;
const testUuid = urlParams.get("testUuid") || undefined;
const userUuid = urlParams.get("userUuid") || undefined;
const filename =
  annex_IdInput + "_" + annex_TitleInput + "_" + Date.now() + ".pdf";
const logoUrl = urlParams.get("OwningOrgaLogo");
const phaseTitle = urlParams.get("phaseTitle");
const userName = urlParams.get("name");
const projectName = urlParams.get("projectName");
const qualityEventName = urlParams.get("qualityEventName");
const qualityEventValue = urlParams.get("qualityEventValue");
const testName = urlParams.get("testName");
const devNumber = urlParams.get("devNumber");
const devName = urlParams.get("devName");
const userToken = urlParams.get("user_token");
const live = urlParams.get("live") || "test";
const bubbleDataApiUrl = `https://e-quali.asklesoft.com/version-${live}/api/1.1`;
const watermarkAPIGatewayUrl =
  live === "live"
    ? "https://pwkvyzoeic.execute-api.eu-west-3.amazonaws.com"
    : "https://tny4yk4ate.execute-api.eu-west-3.amazonaws.com";
const host = document.location.hostname;

// Keys / credentials; note that these should be moved to environment variables
const PSPDFKitLicenseKey = getLicenseKey(host, live);

const AnnexUpload = ({ baseUrl }) => {
  const containerRef = useRef(null);
  const inputFile = useRef(null);
  const [instance, setInstance] = useState();
  const [pdfPages, setPdfPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [blankPdfExist, setBlankPdfExist] = useState(true);
  const [horizontalPosition, setHorizontalPosition] = useState("middle");
  const [verticalPosition, setVerticalPosition] = useState("bottom");

  useEffect(() => {

    const container = containerRef.current;

    (async () => {
      try {
        const instance = await PSPDFKit.load({
          container,
          document: BLANK_PDF,
          baseUrl,
          licenseKey: PSPDFKitLicenseKey,
          headless: true,
        });

        setInstance(instance);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const uploadNewPdf = async (event) => {
    try {
      event.stopPropagation();
      event.preventDefault();

      const files = [...event.target.files].map(async (file) => {
        if (file.type !== "application/pdf") {

          const arrayBuffer = await file.arrayBuffer();

          const pdfOfImage = await fetch(
            "https://trw87xb50d.execute-api.eu-west-3.amazonaws.com",
            {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                imageData: arrayBufferToBuffer(arrayBuffer),
                imageType: file.type,
              }),
              mode: "cors",
            }
          );

          const resp = await pdfOfImage.json();


          const blob = new Blob([Buffer.from(resp.pdfBuffer, "base64")], {
            type: "application/pdf",
          });


          return blob;
        }

        return file;
      });

      const convertedFiles = await Promise.all(files);

      await instance.applyOperations(
        convertedFiles.map((file) => ({
          type: "importDocument",
          afterPageIndex: pdfPages.length === 0 ? 0 : pdfPages.length - 1,
          document: file,
        }))
      );

      if (blankPdfExist) {
        await instance.applyOperations([
          {
            type: "removePages",
            pageIndexes: [0],
          },
        ]);

        setBlankPdfExist(false);
      }

      await updatePdfPages();

      setSelectedPages([]);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePdfPages = async () => {
    try {
      let pagesAsImgsPromise = [];



      for (let index = 0; index < instance.totalPageCount; index++) {
        pagesAsImgsPromise.push(
          instance.renderPageAsImageURL({ width: 400 }, index)
        );
      }

      const pagesAsImgs = await Promise.all(pagesAsImgsPromise);

      const newPdfPages = pagesAsImgs.map((x) => ({ imgUrl: x, rotation: 0 }));

      setPdfPages(newPdfPages);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleSelectedPage = (index) => {
    if (selectedPages.includes(index)) {
      setSelectedPages(selectedPages.filter((x) => x !== index));
    } else {
      setSelectedPages((prevState) => [...prevState, index]);
    }
  };

  const deleteSelectedPage = async () => {
    try {
      if (selectedPages.length !== 0) {
        await instance.applyOperations([
          {
            type: "removePages",
            pageIndexes: selectedPages,
          },
        ]);

        setPdfPages(
          pdfPages.filter((_, index) => !selectedPages.includes(index))
        );
        setSelectedPages([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const rotatePage = async (degree) => {
    try {
      if (selectedPages.length !== 0) {
        await instance.applyOperations([
          {
            type: "rotatePages",
            pageIndexes: selectedPages,
            rotateBy: degree,
          },
        ]);

        const newPdfPages = pdfPages.map((item, index) => {
          if (selectedPages.includes(index)) {
            item.rotation += degree;
          }
          return item;
        });

        setPdfPages(newPdfPages);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const movePageAfter = async () => {
    try {
      if (selectedPages.length !== 0) {
        const afterPageIndex = selectedPages.sort((a, b) => a - b)[0] + 1;

        if (pdfPages.length - 1 >= afterPageIndex) {
          await instance.applyOperations([
            {
              type: "movePages",
              pageIndexes: selectedPages,
              afterPageIndex,
            },
          ]);

          // refresh the pdfPages order
          const pagesBeforeMove = pdfPages.slice(
            0,
            selectedPages.sort((a, b) => a - b)[0]
          );
          const pagesMoved = pdfPages.slice(
            selectedPages.sort((a, b) => a - b)[0],
            selectedPages.sort((a, b) => b - a)[0] + 1
          );
          const pagesAfterMove = pdfPages.slice(
            selectedPages.sort((a, b) => b - a)[0] + 1,
            pdfPages.length
          );
          const firstPageAfterMove = pagesAfterMove.splice(0, 1);

          setPdfPages(
            pagesBeforeMove.concat(
              firstPageAfterMove,
              pagesMoved,
              pagesAfterMove
            )
          );

          setSelectedPages((selectedPages) => selectedPages.map((x) => x + 1));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const movePageBefore = async () => {
    try {
      if (selectedPages.length !== 0) {
        const beforePageIndex = selectedPages.sort((a, b) => a - b)[0] - 1;


        if (beforePageIndex !== -1) {

          await instance.applyOperations([
            {
              type: "movePages",
              pageIndexes: selectedPages,
              beforePageIndex: beforePageIndex,
            },
          ]);

          // refresh the pdfPages order
          const pagesBeforeMove = pdfPages.slice(
            0,
            selectedPages.sort((a, b) => a - b)[0]
          );
          const pagesMoved = pdfPages.slice(
            selectedPages.sort((a, b) => a - b)[0],
            selectedPages.sort((a, b) => b - a)[0] + 1
          );
          const pagesAfterMove = pdfPages.slice(
            selectedPages.sort((a, b) => b - a)[0] + 1,
            pdfPages.length
          );
          const firstPageBeforeMove = pagesBeforeMove.pop();

          setPdfPages(
            pagesBeforeMove.concat(
              pagesMoved,
              firstPageBeforeMove,
              pagesAfterMove
            )
          );

          setSelectedPages((selectedPages) => selectedPages.map((x) => x - 1));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadNewAnnex = async () => {
    try {
      setIsLoading(true);

      const arrayBuffer = await instance.exportPDF();
      const buffer = Buffer.from(arrayBuffer, "base64");

      // 1. upload natural pdf to bubble
      const body = {
        ID: annex_IdInput,
        Title: annex_TitleInput,
        Description: annex_DescriptionInput,
        Project: projUuid,
        CreatedBy: userUuid,
        fileBeforeWatermark: {
          filename,
          type: "application/pdf",
          contents: buffer,
          private: true,
          attach_to: projUuid,
        },
      };

      if (devUuid) {
        body.Linked_Deviation = devUuid;
      } else {
        body.Linked_Filling_testsheet = testUuid;
      }

      const newAnnexResponse = await fetch(`${bubbleDataApiUrl}/obj/Annexes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!newAnnexResponse.ok) {
        throw newAnnexResponse.statusText;
      }

      const { id: annexId } = await newAnnexResponse.json();
      // 2. trigger watermark lambda with bubble uploaded pdf
      // 2. upload watermark pdf to bubble
      const watermarkLambdaResponse = await fetch(watermarkAPIGatewayUrl, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          annexInfo: {
            annexUuid: annexId,
            userToken,
            live,
            projUuid,
            filename,
          },
          watermarkData: {
            logoUrl: logoUrl,
            projectName: projectName,
            phaseName: phaseTitle,
            qualityEventName,
            qualityEventValue,
            testName,
            devNumber,
            devName,
            annex: annex_IdInput + " " + annex_TitleInput,
            addedBy: userName,
            horizontalPosition,
            verticalPosition,
          },
        }),
        mode: "cors",
      });

      if (!watermarkLambdaResponse.ok) {
        throw watermarkLambdaResponse.statusText;
      }

      // 3. trigger new annex workflow
      const newAnnexTriggerResponse = await fetch(
        `${bubbleDataApiUrl}/wf/new_annex`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ annex: annexId }),
        }
      );

      if (!newAnnexTriggerResponse.ok) {
        throw newAnnexTriggerResponse.statusText;
      }

      setIsUploaded(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
    }
  };

  if (isLoading)
    return (
      <>
        <div className="loadingContainer">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
          <p>Please wait.</p>
          <p>We are creating your annex.</p>
          <p>It will just take a moment.</p>
        </div>
      </>
    );

  if (isUploaded)
    return (
      <>
        <div className="loadingContainer">
          <div style={{ width: 200 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              enableBackground="new 0 0 64 64"
            >
              <path
                d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
                fill="#43a047"
              />
            </svg>
          </div>
          <p>Success!</p>
          <p>Your annex was added. You can close the popup</p>
        </div>
      </>
    );

  if (isError)
    return (
      <>
        <div className="loadingContainer">
          <div style={{ width: 200 }}></div>
          <p>Error...</p>
          <p>Please try again or contact the support</p>
        </div>
      </>
    );

  return (
    <section ref={containerRef} className="PSPDFKitContainer">
      <div className="annexUploadContainer">
        <nav className="annexUploadTopBar">
          <div className="barItem" onClick={() => inputFile.current.click()}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
            >
              <path
                d="M14 3.5H7A1.5 1.5 0 0 0 5.5 5v14A1.5 1.5 0 0 0 7 20.5h10a1.5 1.5 0 0 0 1.5-1.5V8L14 3.5z"
                fill="currentColor"
                fillOpacity=".01"
                stroke="currentColor"
                strokeMiterlimit="10"
              ></path>
              <path
                opacity=".66"
                d="M14.496 8.216h3.514l-4.216-4.217v3.514a.712.712 0 0 0 .702.703z"
                fill="currentColor"
              ></path>
              <path
                d="M10.5 13v5m2.5-2.5H8"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeLinecap="round"
              ></path>
            </svg>
            <p>Add new PDF</p>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={uploadNewPdf}
              multiple
            />
          </div>

          <div className="barItem" onClick={deleteSelectedPage}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
            >
              <path
                d="M14 3.5H7A1.5 1.5 0 0 0 5.5 5v14A1.5 1.5 0 0 0 7 20.5h10a1.5 1.5 0 0 0 1.5-1.5V8L14 3.5z"
                fill="currentColor"
                fillOpacity=".01"
                stroke="currentColor"
                strokeMiterlimit="10"
              ></path>
              <path
                opacity=".66"
                d="M14.493 8.215h3.513L13.79 3.999v3.513a.712.712 0 0 0 .703.703z"
                fill="currentColor"
              ></path>
              <path
                d="M13 15.5H8"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeLinecap="round"
              ></path>
            </svg>
            <p>Delete page</p>
          </div>

          <div className="barItem" onClick={() => rotatePage(-90)}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
            >
              <g opacity=".33">
                <path
                  d="M19.5 18.5h.5a1.5 1.5 0 0 0 1.5-1.5V8L18 4.5h-5A1.5 1.5 0 0 0 11.5 6v4.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                ></path>
                <path
                  opacity=".66"
                  d="M17.795 5.004L21 8.209h-2.705a.5.5 0 0 1-.5-.5V5.004z"
                  fill="currentColor"
                ></path>
              </g>
              <path
                d="M5 22.5A1.5 1.5 0 0 1 3.5 21v-5L7 12.5h9a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-1.5 1.5H5z"
                fill="currentColor"
                fillOpacity=".01"
                stroke="currentColor"
              ></path>
              <path
                opacity=".66"
                d="M4.004 16.205L7.209 13v2.705a.5.5 0 0 1-.5.5H4.004z"
                fill="currentColor"
              ></path>
              <path
                d="M10 3.5h-.5a4 4 0 0 0-4 4V10m2-2l-2 2m0 0l-2-2"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeLinecap="round"
              ></path>
            </svg>
            <p>left rotate</p>
          </div>

          <div className="barItem" onClick={() => rotatePage(90)}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
            >
              <g opacity=".33">
                <path
                  d="M12.5 10.5V8L9 4.5H4A1.5 1.5 0 0 0 2.5 6v11A1.5 1.5 0 0 0 4 18.5h.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                ></path>
                <path
                  opacity=".66"
                  d="M8.795 5.004L12 8.209H9.295a.5.5 0 0 1-.5-.5V5.004z"
                  fill="currentColor"
                ></path>
              </g>
              <path
                d="M19 12.5a1.5 1.5 0 0 1 1.5 1.5v5L17 22.5H8A1.5 1.5 0 0 1 6.5 21v-7A1.5 1.5 0 0 1 8 12.5h11z"
                fill="currentColor"
                fillOpacity=".01"
                stroke="currentColor"
              ></path>
              <path
                opacity=".66"
                d="M19.996 18.795L16.791 22v-2.705a.5.5 0 0 1 .5-.5h2.705z"
                fill="currentColor"
              ></path>
              <path
                d="M14 3.5h.5a4 4 0 0 1 4 4V10m-2-2l2 2m0 0l2-2"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeLinecap="round"
              ></path>
            </svg>
            <p>right rotate</p>
          </div>

          <div className="barItem" onClick={movePageBefore}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              focusable="false"
            >
              <path
                d="M8.5 16v3a1.5 1.5 0 0 0 1.5 1.5h10a1.5 1.5 0 0 0 1.5-1.5V8L17 3.5h-7A1.5 1.5 0 0 0 8.5 5v6m5.5 2.5H2.5M4 11l-2.5 2.5L4 16"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M17.25 8.5h4.25l-5-5v4.25a.76.76 0 0 0 .75.75z"
                opacity=".66"
              ></path>
            </svg>
            <p>move before</p>
          </div>

          <div className="barItem" onClick={movePageAfter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              focusable="false"
            >
              <path
                d="M15.5 11V8L11 3.5H4A1.5 1.5 0 0 0 2.5 5v14A1.5 1.5 0 0 0 4 20.5h10a1.5 1.5 0 0 0 1.5-1.5v-3"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></path>
              <path
                d="M11.25 8.5h4.25l-5-5v4.25a.76.76 0 0 0 .75.75z"
                opacity=".66"
              ></path>
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M10 13.5h11.5M20 16l2.5-2.5L20 11"
              ></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
            </svg>
            <p>move after</p>
          </div>

          <WatermarkButton
            horizontalPosition={horizontalPosition}
            setHorizontalPosition={setHorizontalPosition}
            verticalPosition={verticalPosition}
            setVerticalPosition={setVerticalPosition}
          />
        </nav>

        <div className="pdfPagesContainer">
          {pdfPages.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                key={index}
              >
                <img
                  src={item.imgUrl}
                  className={`pdfPage ${
                    selectedPages.includes(index) ? "pdfPage_selected" : ""
                  }`}
                  style={{
                    width: 200,
                    height: 282,
                    transform: `rotate(${item.rotation}deg)`,
                  }}
                  onClick={() => toggleSelectedPage(index)}
                />
                <span>{index + 1}</span>
              </div>
            );
          })}
        </div>

        <nav className="annexUploadBottomBar">
          <button
            className="barItem bottomBarItem"
            onClick={() => {
              if (pdfPages.length >= 1) {
                uploadNewAnnex();
              }
            }}
            style={{ opacity: pdfPages.length >= 1 ? 1 : 0.5 }}
          >
            Save
          </button>
        </nav>
      </div>
    </section>
  );
};

export default AnnexUpload;
