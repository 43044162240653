import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import React, { useState } from "react";
import "./sideNav.css";

const SideNav = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleComponent = () => {
    setIsOpen(!isOpen);
  };

  const setSelectedField = (field) => {
    props.setSelectedField(field);
  };

  return (
    <>
      {isOpen && (
        <div id="sidenav" className="sidenav">
          <div className="sidenav-title-container">
            <h3 className="sidenav-title"> FIELDS </h3>
            <span
              className="material-symbols-outlined chevron-icon chevron-icon-close"
              onClick={toggleComponent}
            >
              chevron_left
            </span>
          </div>

          <p className="sidenav-subtitle">Drag and drop to add to PDF</p>

          <div
            className={`sidenav-element ${
              props.selectedField === "date" ? "sidenav-element--selected" : ""
            }`}
            id="calendarTodayIcon"
            onClick={() => setSelectedField("date")}
          >
            <span className="material-symbols-outlined sidenav-element-icon">
              calendar_today
            </span>
            <span className="sidenav-element-text"> Date </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "date & time"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="accessTimeIcon"
            onClick={() => setSelectedField("date & time")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              schedule
            </span>
            <span className="sidenav-element-text"> Date & Time </span>
          </div>

          <hr className="sidenav-divider"></hr>
          <div
            className={`sidenav-element ${
              props.selectedField === "initials"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="initialsIcon"
            onClick={() => setSelectedField("initials")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              account_circle
            </span>
            <span className="sidenav-element-text"> Initials </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "name" ? "sidenav-element--selected" : ""
            }`}
            id="nameIcon"
            onClick={() => setSelectedField("name")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              person
            </span>
            <span className="sidenav-element-text"> Name </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "firstname"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="firstNameIcon"
            onClick={() => setSelectedField("firstname")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              person_add
            </span>
            <span className="sidenav-element-text"> FirstName </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "lastname"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="lastNameIcon"
            onClick={() => setSelectedField("lastname")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              person_remove
            </span>
            <span className="sidenav-element-text"> LastName </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "email" ? "sidenav-element--selected" : ""
            }`}
            id="emailIcon"
            onClick={() => setSelectedField("email")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              drafts
            </span>
            <span className="sidenav-element-text"> Email Address </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "company"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="companyIcon"
            onClick={() => setSelectedField("company")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              source_environment
            </span>
            <span className="sidenav-element-text"> Company </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "title" ? "sidenav-element--selected" : ""
            }`}
            id="titleIcon"
            onClick={() => setSelectedField("title")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              business_center
            </span>
            <span className="sidenav-element-text"> Title </span>
          </div>

          <hr className="sidenav-divider"></hr>
          <div
            className={`sidenav-element ${
              props.selectedField === "text" ? "sidenav-element--selected" : ""
            }`}
            id="textIcon"
            onClick={() => setSelectedField("text")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              article
            </span>
            <span className="sidenav-element-text"> Text </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "checkmark"
                ? "sidenav-element--selected"
                : ""
            }`}
            id="checkmarkIcon"
            onClick={() => setSelectedField("checkmark")}
          >
            <span className="material-symbols-outlined  sidenav-element-icon">
              check
            </span>
            <span className="sidenav-element-text"> Checkmark </span>
          </div>
          <div
            className={`sidenav-element ${
              props.selectedField === "n/a" ? "sidenav-element--selected" : ""
            }`}
            id="naIcon"
            onClick={() => setSelectedField("n/a")}
          >
            <BrowserNotSupportedIcon className="sidenav-element-icon" />
            <span className="sidenav-element-text"> N/A </span>
          </div>
        </div>
      )}
      {!isOpen && (
        <div onClick={toggleComponent} id="sidenav-hideButton">
          <div className="chevron-icon-open-container">
            <span className="material-symbols-outlined chevron-icon chevron-icon-open">
              chevron_right
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SideNav;
