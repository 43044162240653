import React, { Component } from "react";
import AnnexUpload from "./components/annexUpload";
import PSPDFKit from "./components/pspdfkit";

import AuditBar from "./components/auditBar";
import SideNav from "./components/sideNav";

import "./App.css";
import AnnexExtract from "./components/annexExtract";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const docUuid = urlParams.get("docUuid");
const edit = urlParams.get("edit") || "read";
const docType = urlParams.get("docType") || "unset";
const docUrl = urlParams.get("docUrl") || "unset";
const showAuditTrail = urlParams.get("showAuditTrail");
const includeComments = urlParams.get("includeComments") || "no";

const live = urlParams.get("live") || "test";
const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;
const bubbleDataApiUrl = `https://e-quali.asklesoft.com/version-${live}/api/1.1/obj`;
const compiledUrl = `${bubbleDataApiUrl}/${docType}/${docUuid}`;

// User token for every request
const userToken = urlParams.get("user_token") || "unset";

// Load audit trail if in audit mode
class App extends Component {
  constructor(props) {
    super(props);
    this.auditItemsContainer = [];
    this.state = {
      pdfArrayBuffer: null,
      docObject: null,
      auditRecord: null,
      auditItems: null,
      selectedItem: null,
      selectedField: null,
      isAppReady: false,
    };

    this.updateSelectedItem = this.updateSelectedItem.bind(this);
  }

  async getDocObject(compiledUrl) {
    const doc = await fetch(compiledUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const requestDoc = await doc.json();

    console.log("in getDocObject");
    console.log(requestDoc);

    if (includeComments === "yes") {
      return requestDoc.response;
    }

    const response = requestDoc.response;

    const Annotations = JSON.parse(response.Annotations);

    const annotationsFiltered = Annotations.annotations.filter((annotation) => {
      return annotation.isCommentThreadRoot !== true;
    });

    response.Annotations = JSON.stringify({
      ...Annotations,
      annotations: annotationsFiltered,
      comments: [],
    });

    return response;
  }

  async getDocFile() {
    console.log("in getDocFile");

    const docUrlDecoded = atob(docUrl);
    console.log(docUrlDecoded);

    return docUrlDecoded;
  }

  async loadBuffer() {
    const buffer = await this.getDocFile();
    this.setState({ pdfArrayBuffer: buffer });
    return buffer;
  }

  async loadDocObject() {
    const docObject = await this.getDocObject(compiledUrl);
    this.setState({ docObject: docObject });
    await this.parseAuditRecord(docObject.AuditRecord);
    return docObject;
  }

  async parseAuditRecord(auditRecord) {
    try {
      if (auditRecord !== undefined) {
        // split string on delimiter, and filter empty values
        const _auditItemsArray = auditRecord.split("xxxxxx").filter((x) => x);
        this.setState({ auditItems: _auditItemsArray });
      }
    } catch (e) {
      console.log("Error loading audit trail");
      console.log(e);
    }
  }

  async parseAuditItem(str) {
    try {
      this.auditItemsContainer.push(str.json());
      console.log("push succeeded");
    } catch {
      console.log("Error parsing auditItem");
    }
  }

  updateSelectedItem(newSelectedItem) {
    console.log("update selectedItem");
    this.setState({ selectedItem: newSelectedItem });
  }

  componentDidMount() {
    if (edit !== "annex_upload") {
      Promise.all([this.loadBuffer(), this.loadDocObject(compiledUrl)])
        .then(() => {
          this.setState({ isAppReady: true });
        })
        .catch((e) => {
          console.log("Error loading buffer and docObject");
          console.log(e);
        });
    } else {
      this.setState({ isAppReady: true });
    }
  }

  render() {
    if (!this.state.isAppReady) {
      return null;
    }

    if (edit === "annex_upload") {
      return <AnnexUpload baseUrl={baseUrl} />;
    }

    if (edit === "annex_extract") {
      return (
        <AnnexExtract document={this.state.pdfArrayBuffer} baseUrl={baseUrl} />
      );
    }

    return (
      <div
        className="App"
        id="App"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* <TopNav />  */}
        <div className="Main" style={{ display: "flex", flexDirection: "row" }}>
          {edit === "full" && (
            <SideNav
              selectedField={this.state.selectedField}
              setSelectedField={(newSelectedField) =>
                this.setState({
                  selectedField: newSelectedField,
                })
              }
            />
          )}
          {showAuditTrail === "custom" && (
            <AuditBar
              auditItems={this.state.auditItems}
              selectedItem={this.state.selectedItem}
              updateSelectedItem={this.updateSelectedItem}
            />
          )}
          <div className="App-viewer">
            <PSPDFKit
              ref={this.PSPDFKit}
              document={this.state.pdfArrayBuffer}
              baseUrl={baseUrl}
              docObject={this.state.docObject}
              updateSelectedItem={this.updateSelectedItem}
              resetSelectedField={() =>
                this.setState({
                  selectedField: null,
                })
              }
            />
          </div>
          {/* app viewer */}
        </div>
        {/*main*/}
      </div> //app
    );
  }
}

export default App;
