import { Buffer } from "buffer";
import PSPDFKit from "pspdfkit";
import React, { useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import getLicenseKey from "../../utils/getLicenseKey";
import "../annexUpload/annexUpload.css";
import { WatermarkButton } from "../annexUpload/components/WatermarkButton";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const annex_IdInput = urlParams.get("annex_IdInput") || undefined;
const annex_TitleInput = urlParams.get("annex_TitleInput") || undefined;
const annex_DescriptionInput =
  urlParams.get("annex_DescriptionInput") || undefined;
const projUuid = urlParams.get("projUuid") || undefined;
const devUuid = urlParams.get("devUuid") || undefined;
const testUuid = urlParams.get("testUuid") || undefined;
const userUuid = urlParams.get("userUuid") || undefined;
const filename =
  annex_IdInput + "_" + annex_TitleInput + "_" + Date.now() + ".pdf";
const logoUrl = urlParams.get("OwningOrgaLogo");
const phaseTitle = urlParams.get("phaseTitle");
const userName = urlParams.get("name");
const projectName = urlParams.get("projectName");
const qualityEventName = urlParams.get("qualityEventName");
const qualityEventValue = urlParams.get("qualityEventValue");
const testName = urlParams.get("testName");
const devNumber = urlParams.get("devNumber");
const devName = urlParams.get("devName");
const userToken = urlParams.get("user_token");
const live = urlParams.get("live") || "test";
const bubbleDataApiUrl = `https://e-quali.asklesoft.com/version-${live}/api/1.1`;
const watermarkAPIGatewayUrl =
  live === "live"
    ? "https://pwkvyzoeic.execute-api.eu-west-3.amazonaws.com"
    : "https://tny4yk4ate.execute-api.eu-west-3.amazonaws.com";
const host = document.location.hostname;

// Keys / credentials; note that these should be moved to environment variables
const PSPDFKitLicenseKey = getLicenseKey(host, live);

const AnnexExtract = ({ baseUrl, document }) => {
  const containerRef = useRef(null);
  const [instance, setInstance] = useState();
  const [pdfPages, setPdfPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [horizontalPosition, setHorizontalPosition] = useState("middle");
  const [verticalPosition, setVerticalPosition] = useState("bottom");

  useEffect(() => {
    const container = containerRef.current;

    (async () => {
      try {
        const instance = await PSPDFKit.load({
          container,
          document,
          baseUrl,
          licenseKey: PSPDFKitLicenseKey,
          headless: true,
        });

        await updatePdfPages(instance);

        setInstance(instance);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const updatePdfPages = async (instance) => {
    try {
      let pagesAsImgsPromise = [];


      for (let index = 0; index < instance.totalPageCount; index++) {
        pagesAsImgsPromise.push(
          instance.renderPageAsImageURL({ width: 400 }, index)
        );
      }

      const pagesAsImgs = await Promise.all(pagesAsImgsPromise);


      setPdfPages(pagesAsImgs.map((x) => ({ imgUrl: x, rotation: 0 })));
    } catch (e) {
      console.log(e);
    }
  };

  const toggleSelectedPage = (index) => {
    if (selectedPages.includes(index)) {
      setSelectedPages(selectedPages.filter((x) => x !== index));
    } else {
      setSelectedPages((prevState) => [...prevState, index]);
    }
  };

  const uploadNewAnnex = async () => {
    try {
      setIsLoading(true);

      const arrayBuffer = await instance.exportPDFWithOperations([
        { type: "keepPages", pageIndexes: selectedPages },
      ]);

      const buffer = Buffer.from(arrayBuffer, "base64");

      // 1. upload natural pdf to bubble
      const body = {
        ID: annex_IdInput,
        Title: annex_TitleInput,
        Description: annex_DescriptionInput,
        Project: projUuid,
        CreatedBy: userUuid,
        fileBeforeWatermark: {
          filename,
          type: "application/pdf",
          contents: buffer,
          private: true,
          attach_to: projUuid,
        },
      };

      if (devUuid) {
        body.Linked_Deviation = devUuid;
      } else {
        body.Linked_Filling_testsheet = testUuid;
      }

      const newAnnexResponse = await fetch(`${bubbleDataApiUrl}/obj/Annexes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!newAnnexResponse.ok) {
        throw newAnnexResponse.statusText;
      }

      const { id: annexId } = await newAnnexResponse.json();
      // 2. trigger watermark lambda with bubble uploaded pdf
      // 2. upload watermark pdf to bubble
      const watermarkLambdaResponse = await fetch(watermarkAPIGatewayUrl, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          annexInfo: {
            annexUuid: annexId,
            userToken,
            live,
            projUuid,
            filename,
          },
          watermarkData: {
            logoUrl: logoUrl,
            projectName: projectName,
            phaseName: phaseTitle,
            qualityEventName,
            qualityEventValue,
            testName,
            devNumber,
            devName,
            annex: annex_IdInput + " " + annex_TitleInput,
            addedBy: userName,
            horizontalPosition,
            verticalPosition,
          },
        }),
        mode: "cors",
      });

      if (!watermarkLambdaResponse.ok) {
        throw watermarkLambdaResponse.statusText;
      }

      // 3. trigger new annex workflow
      const newAnnexTriggerResponse = await fetch(
        `${bubbleDataApiUrl}/wf/new_annex`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ annex: annexId }),
        }
      );

      if (!newAnnexTriggerResponse.ok) {
        throw newAnnexTriggerResponse.statusText;
      }

      setIsUploaded(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
    }
  };

  if (isLoading)
    return (
      <>
        <div className="loadingContainer">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
          <p>Please wait.</p>
          <p>We are creating your annex.</p>
          <p>It will just take a moment.</p>
        </div>
      </>
    );

  if (isUploaded)
    return (
      <>
        <div className="loadingContainer">
          <div style={{ width: 200 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              enableBackground="new 0 0 64 64"
            >
              <path
                d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
                fill="#43a047"
              />
            </svg>
          </div>
          <p>Success!</p>
          <p>Your annex was added. You can close the popup</p>
        </div>
      </>
    );

  if (isError)
    return (
      <>
        <div className="loadingContainer">
          <div style={{ width: 200 }}></div>
          <p>Error...</p>
          <p>Please try again or contact the support</p>
        </div>
      </>
    );

  return (
    <section ref={containerRef} className="PSPDFKitContainer">
      <div className="annexUploadContainer">
        <nav className="annexUploadTopBar">
          <WatermarkButton
            horizontalPosition={horizontalPosition}
            setHorizontalPosition={setHorizontalPosition}
            verticalPosition={verticalPosition}
            setVerticalPosition={setVerticalPosition}
          />
        </nav>
        <div className="pdfPagesContainer">
          {pdfPages.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                key={index}
              >
                <img
                  src={item.imgUrl}
                  className={`pdfPage ${
                    selectedPages.includes(index) ? "pdfPage_selected" : ""
                  }`}
                  style={{
                    width: 200,
                    height: 282,
                    transform: `rotate(${item.rotation}deg)`,
                  }}
                  onClick={() => toggleSelectedPage(index)}
                />
                <span>{index + 1}</span>
              </div>
            );
          })}
        </div>

        <nav className="annexUploadBottomBar">
          <button
            className="barItem bottomBarItem"
            onClick={() => {
              if (pdfPages.length >= 1 && selectedPages.length >= 1) {
                uploadNewAnnex();
              }
            }}
            style={
              pdfPages.length >= 1 && selectedPages.length >= 1
                ? {}
                : { opacity: 0.5 }
            }
          >
            Save
          </button>
        </nav>
      </div>
    </section>
  );
};

export default AnnexExtract;
